import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Subtitles, pubnub, TextToSpeech, SpeechToText, MainWrapper } from 'wwtc-sdk';
import {
    endpointSpeechToText,
    endpointTextToSpeech,
    endpointTranslate,
    signedEndpoint,
} from '../constants/connection';
import specialLanguages from '../constants/specialLanguages';
import { Download } from './svg/icons';
import buildSubtitlesFile from '../utils/subtitles';
import { handlePreRequestSubtitles } from './utils';

function Main() {
    // This values come from Login components
    // Also could set up constants values
    // This values are required like props of the MainWrapper
    const history = useHistory();
    const { userName, language, gender, roomName, apiToken, logoHeader, languagesPreTranslate } =
        history.location.state || {
            userName: '',
            language: '',
            gender: '',
            roomName: 'default', // default name
            apiToken: null,
            logoHeader: null,
            languagesPreTranslate: null,
        };
    const myPubnubId = pubnub.userId;

    useEffect(() => {
        if (userName === '') {
            history.push(`/`, {
                userName: userName,
                language: language,
                gender: gender,
            });
        }
        pubnub.addListener({
            signal: function (s) {
                if (s.message.search('kickout') >= 0) {
                    const participantId = s.message.split('kickout_participant-')[1];
                    if (participantId === myPubnubId) {
                        window.location.replace(window.location.origin);
                    }
                }
                if (s.message === 'clean_history_content') {
                    // clean subtitles content
                    document.getElementsByClassName('fa-trash-alt')[0].parentNode.click();
                }
                if (s.message === 'logout_admin') {
                    pubnub.unsubscribeAll();
                    window.location.replace(window.location.origin);
                }
            },
        });
    }, []);

    return (
        <MainWrapper
            autologin={false}
            gender={gender}
            language={language}
            roomName={roomName}
            userName={userName}
        >
            <Header logoHeader={logoHeader} pubnub={pubnub} roomName={roomName} />
            <WrapperComponents apiToken={apiToken} languagesPreTranslate={languagesPreTranslate} />
        </MainWrapper>
    );
}

function Header(props) {
    // initName comes from autologin component
    const { logoHeader, pubnub, initName } = props;

    const handleLogout = async () => {
        if (pubnub) {
            pubnub.unsubscribeAll();
            window.location.replace(window.location.origin);
        } else {
            window.location.replace(window.location.origin);
        }
    };

    return (
        <header>
            <div className="header-left-section">
                <div className="info-user">
                    <img src={`${logoHeader}`} alt="WWTC" />
                </div>
            </div>
            <div className="header-center-section">
                <div className="direct-buttons hidden-mobile">
                    <span>The box 2.0</span>
                </div>
            </div>
            <div className="header-right-section">
                <div className="settings-user">
                    <span className="profile-name hidden-mobile">{initName}</span>
                    &nbsp;&nbsp;
                    <span
                        className="logout-btn"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Logout"
                        data-container="body"
                        onClick={handleLogout}
                    >
                        <i className="fas fa-sign-out-alt"></i>
                    </span>
                </div>
            </div>
        </header>
    );
}

function WrapperComponents(props) {
    // values from login
    const { apiToken, initName, initLanguage, initGender, conferenceName, languagesPreTranslate } =
        props;
    const [isBuildingPdf, setIsBuildingPdf] = useState(false);
    const [modalDownload, setModalDownload] = useState(false);

    const isSpecialLanguage = (subtitles) => {
        const specialSubtitle = specialLanguages.find((special) => {
            var regex = new RegExp(special, 'g');
            var atLeastOneMatches = subtitles.some((e) => regex.test(e.speakerLanguage));
            return atLeastOneMatches;
        });

        return !!specialSubtitle;
    };

    const downloadSubtitlesPDF = (subtitles) => {
        setIsBuildingPdf(true);
        const contentPDF = buildSubtitlesFile(subtitles);

        // detect special languages
        const subtitlesWithSpecialLanguages = isSpecialLanguage(subtitles);

        // download unicode typography
        if (subtitlesWithSpecialLanguages) {
            window.pdfMake.fonts = {
                ArialUnicode: {
                    normal: 'https://sdkresources.s3.amazonaws.com/ArialUnicodeMSFont.ttf',
                    bold: 'https://sdkresources.s3.amazonaws.com/ArialUnicodeMSFont.ttf',
                    italics: 'https://sdkresources.s3.amazonaws.com/ArialUnicodeMSFont.ttf',
                },
            };
        }

        var docDefinition = {
            content: contentPDF,
            defaultStyle: {
                font: subtitlesWithSpecialLanguages ? 'ArialUnicode' : 'Roboto',
                fontSize: 12,
            },
        };

        window.pdfMake.createPdf(docDefinition).download(() => {
            setIsBuildingPdf(false);
        });
    };

    const fetchTextToSpeechCustom = async (endpoint, text, sourceLanguage, voice, itemTtsCall) => {
        try {
            let response = await fetch(endpoint.url.replace(/:sourceLanguage/g, sourceLanguage), {
                method: endpoint.method,
                redirect: 'follow',
                body: JSON.stringify({ text }),
            });
            let data = await response.json();
            try {
                var arrayBuffer = _base64ToArrayBuffer(data.audio);
                let blob = new Blob([arrayBuffer], { type: 'audio/wav' });
                const downloadUrl = window.webkitURL.createObjectURL(blob);
                return { downloadUrl, indexCall: itemTtsCall };
            } catch (e) {
                return {
                    indexCall: itemTtsCall,
                    error: e,
                };
            }
        } catch (err) {
            return {
                indexCall: itemTtsCall,
                error: err,
            };
        }
    };

    const _base64ToArrayBuffer = (base64) => {
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    };

    return (
        <div className="wrapper-components">
            <Subtitles.SubtitlesUI
                username={initName}
                initialSourceLanguage={initLanguage}
                initialTargetLanguage={initLanguage}
                initialVisible={true}
                defaultBilingual={false}
                endpoint={signedEndpoint(apiToken, endpointTranslate)}
                provider="pubnub"
                speechRecognition={true}
                showButtonCloseComponent={false}
                showFlipButton={true}
                showSpeedOption={true}
                style={stylesWrapper.fullContainer}
                ttsOption={true}
                channel={conferenceName}
                languagesPreTranslate={languagesPreTranslate}
                render={(subtitles) =>
                    isBuildingPdf ? null : (
                        <button
                            className="a-element"
                            onClick={() => downloadSubtitlesPDF(subtitles)}
                        >
                            <Download />
                        </button>
                    )
                }
                preRequestSubtitles={handlePreRequestSubtitles}
            />
            <SpeechToText.SpeechToTextUI
                username={initName}
                initialLanguage={initLanguage}
                initialVisible={false}
                initialActive={false}
                withUI={false} // to use only speech recognition
                endpoint={signedEndpoint(apiToken, endpointSpeechToText)}
            />
            <TextToSpeech.TextToSpeechUI
                initialLanguage={initLanguage}
                initialGender={initGender}
                initialVisible={false}
                initialActive={false}
                withUI={false} // to use only audio player
                endpoint={signedEndpoint(apiToken, endpointTextToSpeech)}
                fetchCustom={fetchTextToSpeechCustom}
            />
            {isBuildingPdf ? <ModalDownload onCloseModal={() => setModalDownload(false)} /> : null}
        </div>
    );
}

const ModalDownload = ({ onCloseModal }) => {
    return (
        <div style={stylesWrapper.containerModal}>
            <div style={{ ...stylesWrapper.wrapperModal, height: 180, width: 450 }}>
                <div className="header-container">
                    <div className="header-title">
                        <span>PDF Subtitles</span>
                    </div>
                    <div className="header-options"></div>
                </div>
                <div
                    className="body-container"
                    style={{ ...stylesWrapper.bodyModal, textAlign: 'center' }}
                >
                    <p>Downloading PDF file...</p>
                    <p style={{ fontSize: 14 }}>Please wait, this could take a few seconds.</p>
                </div>
            </div>
        </div>
    );
};

const stylesWrapper = {
    fullSubtitlesContainer: {
        width: 'calc(100% - 10px)',
        height: 'calc(100% - 10px)',
        margin: '5px auto',
        position: 'relative',
        zIndex: 20,
    },
    subtitlesContainer: {
        width: 'calc(100% - 10px)',
        height: 'calc(50% - 10px)',
        margin: '5px auto',
    },
    chatContainer: {
        width: 'calc(100% - 10px)',
        height: 'calc(50% - 10px)',
        margin: '5px auto',
    },
    containerModal: {
        display: 'flex',
        justifyContent: 'center',
        height: 'calc(100vh - 50px)',
        width: '100vw',
        background: 'rgba(1,1,1,0.3)',
        position: 'absolute',
        left: 0,
        top: 0,
        zIndex: 2000,
    },
    bodyModal: {
        marginTop: 15,
        height: 'calc(100% - 30px)',
        overflow: 'auto',
    },
    wrapperModal: {
        padding: '10px 5px',
        backgroundColor: 'white',
        borderRadius: 5,
        height: 500,
        top: 'calc(50% - 250px)',
        width: 320,
        position: 'absolute',
        WebkitBoxShadow: '4px 4px 9px -1px rgba(0,0,0,0.75)',
        MozBoxShadow: '4px 4px 9px -1px rgba(0,0,0,0.75)',
        boxShadow: '4px 4px 9px -1px rgba(0,0,0,0.75)',
    },
    participantItem: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        margin: '10px 0',
        cursor: 'default',
        textIndent: '1em',
    },
    fullContainer: {
        width: 'calc(100% - 20px)',
        height: '100%',
        margin: '10px auto',
    },
};

export default Main;
